import { isNil, omitBy } from 'lodash'

import { PaymentsIdentityFileModel } from '@marketplace-web/domain/payments-identity'
import {
  AddBankAccountArgs,
  AddShippingAddressArgs,
  AddShippingOrderAddressArgs,
  AddUserAddressArgs,
  AuthenticateCreditCardArgs,
  CreateAdminAlertArgs,
  CreateComplaintArgs,
  CreateDirectDonationOrderArgs,
  CreateDsaNoticeAppealArgs,
  CreateDsaNtdAppealArgs,
  CreateLiveChatTicketArgs,
  CreatePaymentsAccountArgs,
  CreatePayoutArgs,
  CreateSupportTicketArgs,
  GetCatalogDefaultFiltersArgs,
  GetCatalogFacetedCategoriesArgs,
  GetCatalogFiltersArgs,
  GetCatalogItemsArgs,
  GetCatalogSearchedFiltersArgs,
  GetClosetPromotionArgs,
  GetElectronicsLandingPageArgs,
  GetInfluencerArgs,
  GetMyOrdersArgs,
  GetNearbyDropOffPointsArgs,
  GetNearbyPickupPointsArgs,
  GetNearbyShippingOptionsArgs,
  GetNewAgainLandingPageArgs,
  GetWardrobeItemsArgs,
  HelpCenterFeedbackArgs,
  InitiateEscrowPaymentArgs,
  InitiateSingleCheckoutPaymentArgs,
  LoginUserArgs,
  PostAdvertisingLeadFormArgs,
  RegisterCreditCardArgs,
  SendIntellectualPropertyInfringementArgs,
  SendItemUploadFeedbackArgs,
  SendOfflineVerificationFeedbackArgs,
  SetBusinessAccountAddressesArgs,
  SetBusinessAccountPoliciesArgs,
  SubmitPaymentsIdentityArgs,
  SubmitTaxpayersSpecialVerificationFormArgs,
  UpdateBusinessAccountProfileDetailsArgs,
  UpdateCheckoutDataArgs,
  UpdateCurrentUserArgs,
  UpdateCurrentUserSettingsArgs,
  UpdateEscrowPaymentDataArgs,
  UpdateFaqEntryFeedbackArgs,
  UpdateFeedbackRatingOptionsArgs,
  UpdateTaxpayerFormDataArgs,
  UpdateUserBundleDiscountsArgs,
} from 'types/api/args'
import { AddressModel } from 'types/models/address'
import { FilterModel } from 'types/models/filter'
import type {
  BusinessAccountPoliciesDto,
  BusinessAccountAddressesDto,
} from 'types/dtos/business-account'
import { transformBrowserAttributesModel } from 'data/transformers/browser-attributes'
import { transformCreditCardEncryptedModel } from 'data/transformers/credit-card-encrypted'
import { transformBusinessAccountAddressModel } from 'data/transformers/business-account'
import { AddressEntryType } from 'constants/address'
import {
  IntellectualPropertyCategory,
  RightsToOrganization,
} from 'constants/intellectual-property-infringement'
import { getItemsInText } from 'pages/HelpCenter/IntellectualPropertyInfringement/utils'
import { SelectedDynamicFilterModel } from 'types/models/dynamic-filter'
import { OrderStatus } from 'constants/my-orders'

const getSelectedDynamicFiltersArgs = (
  selectedDynamicFilters?: Array<SelectedDynamicFilterModel>,
): Record<string, string> => {
  if (!selectedDynamicFilters) return {}

  const dynamicParams: Record<string, string> = {}

  selectedDynamicFilters.forEach(({ type, ids }) => {
    dynamicParams[`${type}_ids`] = ids.join(',')
  })

  return dynamicParams
}

const transformCatalogFiltersModelToParams = ({
  query,
  catalogIds,
  priceFrom,
  priceTo,
  currency,
  sortBy,
  isPopularCatalog,
  catalogFrom,
  disableSearchSaving,
}: Partial<FilterModel>) => ({
  search_text: query,
  catalog_ids: catalogIds?.join(','),
  price_from: priceFrom,
  price_to: priceTo,
  currency,
  order: sortBy,
  popular: isPopularCatalog || null,
  catalog_from: catalogFrom,
  disable_search_saving: disableSearchSaving,
})

export const getClosetPromotionsArgsToParams = (args: GetClosetPromotionArgs) => {
  const staticArgs = {
    page: args.page,
    per_page: args.perPage,
    exclude_member_ids: args.excludedUserIds?.join(','),
    search_session_id: args.searchSessionId,
    screen_name: args.screenName,
    ...transformCatalogFiltersModelToParams(args),
  }

  return omitBy(
    { ...staticArgs, ...getSelectedDynamicFiltersArgs(args.selectedDynamicFilters) },
    isNil,
  )
}

export const getCatalogItemsArgsToParams = (args: GetCatalogItemsArgs) => {
  const staticArgs = {
    page: args.page,
    per_page: args.perPage,
    time: args.time,
    search_session_id: args.searchSessionId,
    global_search_session_id: args.globalSearchSessionId || null,
    global_catalog_browse_session_id: args.globalCatalogBrowseSessionId || null,
    ...transformCatalogFiltersModelToParams(args),
  }

  return omitBy(
    { ...staticArgs, ...getSelectedDynamicFiltersArgs(args.selectedDynamicFilters) },
    isNil,
  )
}

export const getCatalogFacetedCategoriesArgsToParams = ({
  query,
  catalogIds,
  priceFrom,
  priceTo,
  currency,
  sortBy,
  time,
  isPopularCatalog,
  catalogFrom,
  selectedDynamicFilters,
  filterSearchCode,
  filterSearchText,
}: GetCatalogFacetedCategoriesArgs) => {
  const staticArgs = {
    search_text: query,
    catalog_ids: catalogIds.join(','),
    price_from: priceFrom,
    price_to: priceTo,
    currency,
    order: sortBy,
    time,
    popular: isPopularCatalog || null,
    catalog_from: catalogFrom,
    filter_search_code: filterSearchCode,
    filter_search_text: filterSearchText,
  }

  return omitBy({ ...staticArgs, ...getSelectedDynamicFiltersArgs(selectedDynamicFilters) }, isNil)
}

export const getCatalogFiltersArgsToParams = ({
  query,
  catalogIds,
  priceFrom,
  priceTo,
  currency,
  sortBy,
  time,
  isPopularCatalog,
  catalogFrom,
  selectedDynamicFilters,
  filterSearchCode,
  filterSearchText,
}: GetCatalogFiltersArgs) => {
  const staticArgs = {
    search_text: query,
    catalog_ids: catalogIds.join(','),
    price_from: priceFrom,
    price_to: priceTo,
    currency,
    order: sortBy,
    time,
    popular: isPopularCatalog || null,
    catalog_from: catalogFrom,
    filter_search_code: filterSearchCode,
    filter_search_text: filterSearchText,
  }

  return omitBy({ ...staticArgs, ...getSelectedDynamicFiltersArgs(selectedDynamicFilters) }, isNil)
}

export const getCatalogSearchedFiltersArgsToParams = ({
  filterSearchCode,
  filterSearchText,
  query,
  catalogIds,
  priceFrom,
  priceTo,
  currency,
  sortBy,
  isPopularCatalog,
  catalogFrom,
  selectedDynamicFilters,
}: GetCatalogSearchedFiltersArgs) => {
  const staticArgs = {
    filter_search_code: filterSearchCode,
    filter_search_text: filterSearchText,
    search_text: query,
    catalog_ids: catalogIds.join(','),
    price_from: priceFrom,
    price_to: priceTo,
    currency,
    order: sortBy,
    popular: isPopularCatalog || null,
    catalog_from: catalogFrom,
  }

  return omitBy({ ...staticArgs, ...getSelectedDynamicFiltersArgs(selectedDynamicFilters) }, isNil)
}

export const getCatalogDefaultFiltersArgsToParams = ({
  catalogIds,
}: GetCatalogDefaultFiltersArgs) => ({
  catalog_ids: catalogIds.join(','),
})

export const getWardrobeItemsArgsToParams = ({
  currentPage,
  perPage,
  order,
  filter,
  catalogIds,
  selectedItemId,
}: GetWardrobeItemsArgs) => ({
  page: currentPage + 1,
  per_page: perPage,
  cond: filter,
  order,
  catalog_ids: catalogIds?.join(','),
  selected_item_id: selectedItemId,
})

export const createPaymentsAccountArgsToParams = (args: CreatePaymentsAccountArgs) => {
  return {
    payments_account: {
      user_address_id: args.addressId,
      first_name: args.firstName || undefined,
      last_name: args.lastName || undefined,
      birthdate: args.birthdate || undefined,
      ssn_serial: args.ssnNumber || undefined,
      personal_id_number: args.personalIdNumber || undefined,
      nationality: args.nationality ? { code: args.nationality.code } : undefined,
      pep_details: args.pepDetails
        ? {
            type: args.pepDetails.type,
            position: args.pepDetails.position,
            organisation: args.pepDetails.organisation,
            holds_position_from: args.pepDetails.holdsPositionFrom,
            associated_pep_full_name: args.pepDetails.associatedPepFullName,
          }
        : undefined,
    },
  }
}

export const createPayoutArgsToParams = ({
  userId,
  bankAccountId,
  amount,
  currency,
}: CreatePayoutArgs) => ({
  payout: {
    seller_id: userId,
    bank_account_id: bankAccountId,
    amount,
    currency,
  },
})

export const addUserAddressArgsToParams = ({ address }: AddUserAddressArgs) => ({
  entry_type: address.entryType,
  country_id: address.countryId,
  name: address.name,
  country: address.country,
  city: address.city,
  line1: address.line1,
  line2: address.line2,
  postal_code: address.postalCode,
})

export const addShippingAddressArgsToParams = ({ address }: AddShippingAddressArgs) => ({
  entry_type: AddressEntryType.Shipping,
  country_id: address.countryId,
  name: address.name,
  country: address.country,
  city: address.city,
  line1: address.line1,
  line2: address.line2,
  postal_code: address.postalCode,
})

export const addShippingOrderAddressArgsToParams = ({
  addressId,
}: AddShippingOrderAddressArgs) => ({
  address_id: addressId,
})

export const updateCurrentUserSettingsArgsToParams = ({
  isNewsletterSubscribed,
  allowMyFavouriteNotifications,
  isLocationPublic,
  isPublishPhotosAgreed,
  thirdPartyTracking,
  allowPersonalization,
  showRecentlyViewedItems,
}: UpdateCurrentUserSettingsArgs) => ({
  is_newsletter_subscriber: isNewsletterSubscribed,
  allow_my_favourite_notifications: allowMyFavouriteNotifications,
  is_location_public: isLocationPublic,
  is_publish_photos_agreed: isPublishPhotosAgreed,
  third_party_tracking: thirdPartyTracking,
  allow_personalization: allowPersonalization,
  show_recently_viewed_items: showRecentlyViewedItems,
})

export const updateBusinessAccountProfileDetailsArgsToParams = ({
  username,
  contactEmail,
  contactNumber,
  vat,
  about,
  photoTempUuid,
  isContactNumberPersonal,
  isContactEmailPersonal,
}: UpdateBusinessAccountProfileDetailsArgs) => ({
  name: username,
  email: contactEmail,
  phone_number: contactNumber,
  text_about: about,
  photo_temp_uuid: photoTempUuid,
  vat,
  personal_phone_number: isContactNumberPersonal,
  personal_email: isContactEmailPersonal,
})

export const updateCurrentUserArgsToParams = ({
  realName,
  cityId,
  countryId,
  gender,
  birthday,
  about,
  username,
  photoTempUuid,
  isCityVisible,
  isOnHoliday,
  isInternationalTradingEnabled,
  fingerprint,
}: UpdateCurrentUserArgs) => ({
  user: {
    real_name: realName,
    city_id: cityId,
    country_id: countryId,
    gender,
    birthday,
    text_about: about,
    login: username,
    photo_temp_uuid: photoTempUuid,
    user_settings: {
      is_location_public: isCityVisible,
      on_holiday: isOnHoliday,
      international_trading_enabled: isInternationalTradingEnabled,
    },
  },
  fingerprint,
})

export const getNearbyShipmentOptionsArgsToParams = ({
  countryCode,
  latitude,
  longitude,
  addressLine,
  postalCode,
  city,
  limit,
  shouldLabelNearestPoints,
}: GetNearbyShippingOptionsArgs) =>
  omitBy(
    {
      country_code: countryCode,
      latitude,
      longitude,
      address_line: addressLine,
      postal_code: postalCode,
      city,
      limit,
      should_label_nearest_points: shouldLabelNearestPoints,
    },
    isNil,
  )

export const getNearbyPickupPointsArgsToParams = ({
  countryCode,
  latitude,
  longitude,
}: GetNearbyPickupPointsArgs) => ({
  country_code: countryCode,
  latitude,
  longitude,
})

export const addBankAccountArgsToParams = ({
  name,
  accountNumber,
  routingNumber,
  addressId,
  spendingType,
}: AddBankAccountArgs) => ({
  name,
  user_address_id: addressId,
  account_number: accountNumber,
  routing_number: routingNumber,
  spending_type: spendingType,
})

export const updateCheckoutDataArgsToParams = ({
  toAddressId,
  deliveryType,
  creditCardId,
  packageTypeId,
  payInMethodId,
  pickupPointCode,
  rateUuid,
  isOfflineVerificationEnabled,
  isElectronicsVerificationEnabled,
  pointUuid,
  rootRateUuid,
}: UpdateCheckoutDataArgs) => ({
  transaction: {
    shipment: {
      to_address_id: toAddressId,
      delivery_type: deliveryType,
      package_type_id: packageTypeId,
      pickup_point_code: pickupPointCode,
      rate_uuid: rateUuid,
      point_uuid: pointUuid,
      root_rate_uuid: rootRateUuid,
    },
    buyer_debit: {
      pay_in_method_id: payInMethodId,
      external_card_code: creditCardId,
    },
    offline_verification: {
      enabled: isOfflineVerificationEnabled,
    },
    electronics_verification: {
      enabled: isElectronicsVerificationEnabled,
    },
  },
})

export const singleCheckoutPaymentInitiationArgsToParams = ({
  cvv,
  checksum,
  blikCode,
  isSingleUseCard,
  browserAttributes,
}: InitiateSingleCheckoutPaymentArgs) => ({
  checksum,
  payment_options: {
    blik_code: blikCode,
    single_use_card: isSingleUseCard,
    ...(cvv ? { encrypted_card_details: { security_code: cvv } } : {}),
    browser_info: transformBrowserAttributesModel(browserAttributes),
  },
})

export const escrowPaymentInitiationArgsToParams = ({
  blikCode,
  isSingleUse,
  browserAttributes,
  transactionChecksum,
  encryptedCvv,
}: InitiateEscrowPaymentArgs) => ({
  blik_code: blikCode,
  single_use_card: isSingleUse,
  checksum: transactionChecksum,
  browser_attributes: transformBrowserAttributesModel(browserAttributes),
  encrypted_card_details:
    typeof encryptedCvv === 'string'
      ? {
          security_code: encryptedCvv,
        }
      : undefined,
})

export const escrowPaymentDataUpdateArgsToParams = ({
  paymentData,
}: UpdateEscrowPaymentDataArgs) => ({
  payment_data: { ...paymentData },
})

export const sendHelpCenterFeedbackArgsToParams = ({
  expirationDate,
  ticketId,
  rating,
  effort,
  comment,
  digest,
}: HelpCenterFeedbackArgs) => ({
  helpdesk_feedback: {
    ticket_id: ticketId,
    rating,
    effort,
    comment,
  },
  expiration_date: expirationDate,
  digest,
})

export const sendItemUploadFeedbackArgsToParams = ({
  rating,
  comment,
  finalized,
  itemId,
  feedbackStatementIds,
}: SendItemUploadFeedbackArgs) => ({
  item_id: itemId,
  rating,
  comment,
  finalized,
  feedback_statement_ids: feedbackStatementIds,
})

export const updateFeedbackRatingOptionsArgsToParams = ({
  rating,
  message,
  feedbackStatementParentId,
  feedbackStatementIds,
  finalized,
}: UpdateFeedbackRatingOptionsArgs) => ({
  feedback_ratings: {
    finalized,
    rating,
    comment: message,
    feedback_statement_parent_id: feedbackStatementParentId,
    feedback_statement_ids: feedbackStatementIds,
  },
})

const addressToParams = (address: AddressModel | null) =>
  (address && {
    country_id: address.countryId,
    name: address.name || null,
    city: address.city || null,
    line1: address.line1 || null,
    line2: address.line2 || null,
    postal_code: address.postalCode || null,
  }) ||
  null

const documentFileToParams = (file: PaymentsIdentityFileModel) => ({
  type: file.type,
  files: file.files.map(({ identifier, body, mediaType }) => ({
    identifier,
    body,
    media_type: mediaType,
  })),
})

export const submitPaymentsIdentityArgsToParams = ({
  firstName,
  lastName,
  birthdate,
  nationality,
  ssnSerial,
  personalIdNumber,
  address,
  ubos,
  documents,
}: SubmitPaymentsIdentityArgs) => {
  const transformedDocuments: Array<{
    type: string
    files: Array<{
      identifier: string
      uuid?: string | null
      mediaType?: string | null
      body?: string | null
    }>
  }> = documents?.map(document => documentFileToParams(document)) || []

  return {
    first_name: firstName,
    last_name: lastName,
    nationality,
    birthdate,
    ssn_serial: ssnSerial,
    personal_id_number: personalIdNumber,
    address: addressToParams(address),
    ubos:
      ubos?.map(ubo => ({
        first_name: ubo.firstName,
        last_name: ubo.lastName,
        birthday: ubo.birthday,
        nationality: ubo.nationality,
        birthplace_city: ubo.birthplaceCity,
        birthplace_country: ubo.birthplaceCountry,
        address: addressToParams(ubo.address),
      })) || null,
    documents: transformedDocuments,
  }
}

export const setBusinessAccountPoliciesArgsToParams = ({
  termsAndConditions,
  returnPolicy,
  additionalInformation,
}: SetBusinessAccountPoliciesArgs): BusinessAccountPoliciesDto => ({
  terms_and_conditions: termsAndConditions,
  return_policy: returnPolicy,
  additional_information: additionalInformation,
})

export const setBusinessAccountAddressesArgsToParams = ({
  businessAddress,
  returnAddress,
}: Pick<
  SetBusinessAccountAddressesArgs,
  'businessAddress' | 'returnAddress'
>): BusinessAccountAddressesDto => ({
  business_address: businessAddress && transformBusinessAccountAddressModel(businessAddress),
  return_address: returnAddress && transformBusinessAccountAddressModel(returnAddress),
})

export const registerCreditCardArgsToParams = ({
  id,
  brand,
  registrationData,
  encryptedCardDetails,
  isSingleUse,
  browserAttributes,
  source,
  isCobranded,
}: RegisterCreditCardArgs) => ({
  browser_attributes: transformBrowserAttributesModel(browserAttributes),
  credit_card_registration: {
    id,
    registration_data: registrationData,
    encrypted_card_details: encryptedCardDetails
      ? transformCreditCardEncryptedModel(encryptedCardDetails)
      : undefined,
  },
  credit_card: {
    brand,
    single_use: isSingleUse,
    co_branded: isCobranded,
  },
  redirect_params: {
    order_id: source.orderId,
    entity_id: source.entityId,
    entity_type: source.entityType,
  },
})

export const authenticateCreditCardArgsToParams = ({
  paymentData,
  registrationId,
}: AuthenticateCreditCardArgs) => ({
  credit_card_registration: {
    id: registrationId,
  },
  payment_data: {
    ...paymentData,
  },
})

export const getCreateSupportTicketArgs = ({
  faqEntryId,
  ch,
  problemTextId,
  transactionId,
  threadId,
  tempUuid,
  message,
  isEnglishAllowed,
  itemId,
  memberId,
  email,
  helpCenterSessionId,
}: CreateSupportTicketArgs) => ({
  faq_entry_id: faqEntryId,
  ch,
  transaction_id: transactionId,
  thread_id: threadId,
  problem_text_id: problemTextId,
  temp_uuid: tempUuid,
  help_contact_form: {
    message,
    english_allowed: isEnglishAllowed,
    item_id: itemId,
    transaction_id: transactionId,
    member_id: memberId,
    email,
  },
  help_center_session_id: helpCenterSessionId,
})

export const createLiveChatTicketArgs = ({
  transactionId,
  ch,
  englishAllowed,
  message,
  helpCenterSessionId,
}: CreateLiveChatTicketArgs) => ({
  ch,
  transaction_id: transactionId,
  english_allowed: englishAllowed,
  message,
  help_center_session_id: helpCenterSessionId,
})

export const getCreateComplaintArgs = ({
  faqEntryId,
  ch,
  problemTextId,
  transactionId,
  threadId,
  tempUuid,
  message,
  isEnglishAllowed,
  itemId,
  memberId,
  email,
  complaintOrderItems,
}: CreateComplaintArgs) => ({
  faq_entry_id: faqEntryId,
  ch,
  transaction_id: transactionId,
  thread_id: threadId,
  problem_text_id: problemTextId,
  temp_uuid: tempUuid,
  help_contact_form: {
    message,
    english_allowed: isEnglishAllowed,
    item_id: itemId,
    transaction_id: transactionId,
    member_id: memberId,
    email,
  },
  complaint_order_items: complaintOrderItems?.map(item => ({
    order_item_id: item.orderItemId,
    reason: item.code,
  })),
})

export const createAdminAlertArgsToParams = ({
  adminAlert: { refType, reportReasonId, refId, message, options },
}: CreateAdminAlertArgs) => {
  const getOptionParams = () => {
    return omitBy(
      {
        reported_message_id: options?.reportedMessageId,
        reported_photo_id: options?.reportedPhotoId,
      },
      isNil,
    )
  }

  return {
    admin_alert: omitBy(
      {
        ref_type: refType,
        ref_id: refId,
        report_reason_id: reportReasonId,
        message,
        options: options ? getOptionParams() : undefined,
      },
      isNil,
    ),
  }
}

export const authenticateUserArgsToParams = ({ grantType, ...loginUserArgs }: LoginUserArgs) => {
  if ('verificationCode' in loginUserArgs) {
    return {
      control_code: loginUserArgs.controlCode,
      password_type: loginUserArgs.passwordType,
      verification_code: loginUserArgs.verificationCode,
      grant_type: grantType,
      is_trusted_device: loginUserArgs.isTrustedDevice,
    }
  }

  if ('controlCode' in loginUserArgs) {
    return {
      control_code: loginUserArgs.controlCode,
      grant_type: grantType,
      username: loginUserArgs.username,
      password: loginUserArgs.password,
      fingerprint: loginUserArgs.fingerprint,
    }
  }

  const { ...args } = loginUserArgs

  return {
    ...args,
    grant_type: grantType,
  }
}

const getContactDetails = ({
  rightsToOrganization,
  firstName,
  lastName,
  email,
  company,
  address,
  city,
  worldCountry,
  postalCode,
}: SendIntellectualPropertyInfringementArgs) => {
  switch (rightsToOrganization?.value) {
    case RightsToOrganization.Owner:
    case RightsToOrganization.Agent:
    case RightsToOrganization.Lawyer:
    case RightsToOrganization.IpOwner:
    case RightsToOrganization.IpAgent:
    case RightsToOrganization.IpLawyer:
    case RightsToOrganization.Organization:
    case RightsToOrganization.TrustedFlagger:
      return {
        first_name: firstName,
        last_name: lastName,
        email,
        company,
        address,
        city,
        country_code: worldCountry?.code,
        zip_code: postalCode,
      }
    case RightsToOrganization.Individual:
      return {
        first_name: firstName,
        last_name: lastName,
        email,
      }
    default:
      return {}
  }
}

const getIpInfringementParams = ({
  intellectualPropertyCategory,
  rightsConsistOf,
  intellectualPropertyOwner,
  intellectualPropertyName,
  registrationNumber,
  proofOfRights,
  brand,
  description,
  attachments,
  infringingUrlsTextArea,
}: SendIntellectualPropertyInfringementArgs) => {
  const getCategoryDependantFields = () => {
    if (intellectualPropertyCategory === IntellectualPropertyCategory.Other) {
      return { rights_consist_of: rightsConsistOf }
    }

    return {
      owner: intellectualPropertyOwner,
      name: intellectualPropertyName,
      registration_number: registrationNumber,
    }
  }

  const illegalContentUrls = getItemsInText(infringingUrlsTextArea).map(url => ({ url }))

  return {
    category: intellectualPropertyCategory,
    ...getCategoryDependantFields(),
    proof_of_rights: proofOfRights,
    brand: brand?.title,
    description,
    attachments: attachments?.map(attachment => ({ temp_uuid: attachment.id })),
    illegal_contents: illegalContentUrls,
  }
}

const getGenericReportParams = ({
  illegalContentType,
  reportReasonType,
  description,
  infringingUrlsTextArea,
  attachments,
}: SendIntellectualPropertyInfringementArgs) => ({
  illegal_content_type: illegalContentType,
  report_reason_code: reportReasonType,
  description,
  illegal_contents: getItemsInText(infringingUrlsTextArea).map(url => ({ url })),
  attachments: attachments?.map(attachment => ({ temp_uuid: attachment.id })),
})

const getPropertyRightsParams = (form: SendIntellectualPropertyInfringementArgs) => {
  switch (form.rightsToOrganization?.value) {
    case RightsToOrganization.Owner:
    case RightsToOrganization.Agent:
    case RightsToOrganization.Lawyer:
    case RightsToOrganization.IpOwner:
    case RightsToOrganization.IpAgent:
    case RightsToOrganization.IpLawyer:
      return getIpInfringementParams(form)
    case RightsToOrganization.Individual:
    case RightsToOrganization.Organization:
    case RightsToOrganization.TrustedFlagger:
      return getGenericReportParams(form)
    default:
      return {}
  }
}

export const sendIntellectualPropertyInfringementArgsToParams = (
  form: SendIntellectualPropertyInfringementArgs,
) => {
  return {
    rights_to_organization: form.rightsToOrganization?.value,
    contacts: getContactDetails(form),
    property_rights: getPropertyRightsParams(form),
  }
}

export const createDirectDonationOrderArgsToParams = ({
  charityCode,
  amount,
  currencyCode,
}: CreateDirectDonationOrderArgs) => {
  return {
    charity_code: charityCode,
    contribution: {
      amount,
      currency_code: currencyCode,
    },
  }
}

export const updateFaqEntryFeedbackArgsToParams = ({
  feedbackUuid,
  accessChannel,
  ...feedbackRatings
}: UpdateFaqEntryFeedbackArgs) => ({
  feedback_uuid: feedbackUuid,
  access_channel: accessChannel,
  ...updateFeedbackRatingOptionsArgsToParams(feedbackRatings),
})

export const updateUserBundleDiscountsArgsToParams = ({
  isEnabled,
  discounts,
}: UpdateUserBundleDiscountsArgs) => ({
  bundle_discount: {
    enabled: isEnabled,
    discounts: discounts?.map(({ fraction, minimalItemCount }) => ({
      fraction,
      minimal_item_count: minimalItemCount,
    })),
  },
})

export const updateTaxpayerFormDataArgsToParams = ({
  countryOfTaxResidency,
  firstName,
  lastName,
  birthdate,
  billingAddressId,
  taxIdentificationNumber,
  birthplaceCountry,
  birthplaceCity,
  businessName,
  businessCode,
  secondaryBusinessCode,
  vatNumber,
  businessAddressId,
  businessEstablishmentCountries,
}: UpdateTaxpayerFormDataArgs) => ({
  taxpayer: {
    first_name: firstName,
    last_name: lastName,
    birthdate,
    country_of_tax_residency: countryOfTaxResidency,
    tin: taxIdentificationNumber,
    billing_address_id: billingAddressId,
    birthplace_country: birthplaceCountry,
    birthplace_city: birthplaceCity,
    business_name: businessName,
    business_code: businessCode,
    secondary_business_code: secondaryBusinessCode,
    vat_number: vatNumber,
    business_address_id: businessAddressId,
    business_establishment_countries: businessEstablishmentCountries,
  },
})

export const addNearbyDropOffPointArgsToParams = ({
  labelTypeId,
  countryCode,
  latitude,
  longitude,
  limit,
}: GetNearbyDropOffPointsArgs) => ({
  label_type_id: labelTypeId,
  country_code: countryCode,
  latitude,
  longitude,
  limit,
})

export const getDsaNoticeCreateAppealArgs = ({
  threadId,
  message,
  attachments,
  isEnglishAllowed,
}: CreateDsaNoticeAppealArgs) => ({
  thread_id: threadId,
  message,
  attachments,
  english_allowed: isEnglishAllowed,
})

export const getCreateDsaNtdAppealArgs = ({
  referenceId,
  message,
  isEnglishAllowed,
}: CreateDsaNtdAppealArgs) => ({
  reference_id: referenceId,
  message,
  english_allowed: isEnglishAllowed,
})

export const postAdvertisingLeadFormArgsToParams = ({
  firstName,
  lastName,
  email,
  phoneNr,
  companyType,
  companyName,
  estimatedBudget,
  targetCountry,
  dealType,
  comment,
}: PostAdvertisingLeadFormArgs) => ({
  first_name: firstName,
  last_name: lastName,
  phone_nr: phoneNr,
  company_type: companyType,
  company_name: companyName,
  estimated_budget: estimatedBudget,
  target_country: targetCountry,
  deal_type: dealType,
  email,
  comment,
})

export const sendOfflineVerificationFeedbackArgsToParams = ({
  rating,
  comment,
  finalized,
  feedbackStatementIds,
}: SendOfflineVerificationFeedbackArgs) => ({
  feedback_ratings: {
    rating,
    comment,
    finalized,
    feedback_statement_ids: feedbackStatementIds,
  },
})

export const submitTaxpayersSpecialVerificationFormArgsToParams = ({
  inputFields,
  documents,
  x_thumbprint,
}: SubmitTaxpayersSpecialVerificationFormArgs) => ({
  taxpayer: {
    ...inputFields,
  },
  documents: documents.map(document => ({
    part: document.part,
    type: document.supportingDocumentType,
    base64: x_thumbprint ? document.encryptedBase64 : document.originalBase64,
    image_file_type: document.imageFileType,
  })),
})

export const getMyOrdersArgsToParams = ({ type, status, perPage, page }: GetMyOrdersArgs) => ({
  type,
  status: status === OrderStatus.Cancelled ? 'canceled' : status,
  per_page: perPage,
  page,
})

export const getInfluencerArgsToParams = ({
  locale,
  countryCode,
  slug,
  populate,
}: GetInfluencerArgs) => ({
  locale,
  filters: {
    country_code: {
      $eq: countryCode,
    },
    slug: {
      $eq: slug,
    },
  },
  populate,
})

export const getElectronicsLandingPageArgsToParams = ({
  locale,
  countryCode,
  populate,
}: GetElectronicsLandingPageArgs) => ({
  locale,
  filters: {
    country_code: {
      $eq: countryCode,
    },
  },
  populate,
})

export const getNewAgainLandingPageArgsToParams = ({
  locale,
  countryCode,
  populate,
}: GetNewAgainLandingPageArgs) => ({
  locale,
  filters: {
    country_code: {
      $eq: countryCode,
    },
  },
  populate,
})
